import { createContext, useEffect, useState, useContext } from "react";
import { axiosInstance } from "../service/axiosInstance";
import { AuthContext } from "./AuthContext";

export const LoginUserContext = createContext(null);

export const LoginUserProvider = ({ children }) => {
  const { isLogin } = useContext(AuthContext);

  const [user, setUser] = useState(null);

  useEffect(() => {
    axiosInstance
      .get("/auth/admin/login")
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
      });
  }, [isLogin]);

  return (
    <LoginUserContext.Provider value={{ user }}>
      {children}
    </LoginUserContext.Provider>
  );
};
