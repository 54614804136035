import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'Ana Sayfa',
    path: '/',
    icon: icon('ic_home'),
  },
  {
    title: 'Kullanıcılar',
    path: '/users',
    icon: icon('ic_users'),
  },
  {
    title: 'Kazançlar',
    path: '/profits',
    icon: icon('ic_profits'),
  },
  {
    title: 'Şirketler',
    path: '/company',
    icon: icon('ic_companies'),
  },
  {
    title: 'Kariyerler',
    path: '/career',
    icon: icon('ic_careers'),
  },
  {
    title: 'Adminler',
    path: '/admin-user',
    icon: icon('ic_admins'),
  },
  {
    title: 'Bloglar',
    path: '/blog',
    icon: icon('ic_blogs'),
  },
  {
    title: 'Bildirimler',
    path: '/notifications',
    icon: icon('ic_notification'),
  },
  {
    title: 'İletişimler',
    path: '/contact-us',
    icon: icon('ic_chats'),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
