import { lazy, Suspense } from "react";
import { Outlet, Navigate, useRoutes } from "react-router-dom";

import DashboardLayout from "../../layouts/dashboard";

//Career pages
export const CareerPage = lazy(() => import("../private/career"));
export const CareerAddPage = lazy(() => import("../private/career/pages/Add"));
export const CareerEditPage = lazy(() =>
  import("../private/career/pages/Edit")
);

//Company pages
export const CompanyPage = lazy(() => import("../private/company"));
export const CompanyAddPage = lazy(() =>
  import("../private/company/pages/Add")
);
export const CompanyEditPage = lazy(() =>
  import("../private/company/pages/Edit")
);

//Admin pages
export const AdminPage = lazy(() => import("../private/adminUser"));
export const AdminAddPage = lazy(() =>
  import("../private/adminUser/pages/Add")
);
export const AdminEditPage = lazy(() =>
  import("../private/adminUser/pages/Edit")
);

//User pages
export const UserPage = lazy(() => import("../private/user"));
export const UserAddPage = lazy(() => import("../private/user/pages/Add"));
export const UserEditPage = lazy(() => import("../private/user/pages/Edit"));

//Public pages
export const HomePage = lazy(() => import("../private/home"));
export const LoginPage = lazy(() => import("../public/login"));
export const Page404 = lazy(() => import("../public/page-not-found"));

//Blog pages
export const BlogPage = lazy(() => import("../private/blog"));
export const BlogAddPage = lazy(() => import("../private/blog/pages/Add"));
export const BlogEditPage = lazy(() => import("../private/blog/pages/Edit"));
export const BlogDetailPage = lazy(() =>
  import("../private/blog/pages/Detail")
);

//Notification pages
export const NotificationPage = lazy(() => import("../private/notification"));

//Profits pages
export const ProfitsPage = lazy(() => import("../private/profits"));
export const ProfitsDetailPage = lazy(() =>
  import("../private/profits/pages/Detail")
);

//Contact us pages
export const ContactUs = lazy(() => import("../private/contactus"));
export const ContactUsDetail = lazy(() => import("../private/contactus/pages/Detail"));

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { element: <HomePage />, index: true },
        {
          path: "career/*",
          element: <CareerPage />,
          children: [
            { path: "career/add", element: <CareerAddPage /> },
            { path: "career/edit/:id", element: <CareerEditPage /> },
          ],
        },
        {
          path: "company/*",
          element: <CompanyPage />,
          children: [
            { path: "company/add", element: <CompanyAddPage /> },
            { path: "company/edit/:id", element: <CompanyEditPage /> },
          ],
        },
        {
          path: "admin-user/*",
          element: <AdminPage />,
          children: [
            { path: "admin-user/add", element: <AdminAddPage /> },
            { path: "admin-user/edit/:id", element: <AdminEditPage /> },
          ],
        },
        {
          path: "users/*",
          element: <UserPage />,
          children: [
            { path: "users/add", element: <UserAddPage /> },
            { path: "users/edit/:id", element: <UserEditPage /> },
          ],
        },
        {
          path: "profits/*",
          element: <ProfitsPage />,
          children: [
            { path: "profits/detail/:id", element: <ProfitsDetailPage /> },
          ],
        },
        {
          path: "notifications/*",
          element: <NotificationPage />,
          children: [
            { path: "users/add", element: <UserAddPage /> },
            { path: "users/detail/:id", element: <UserEditPage /> },
          ],
        },
        {
          path: "blog/*",
          element: <BlogPage />,
          children: [
            { path: "blog/add", element: <BlogAddPage /> },
            { path: "blog/edit/:id", element: <BlogEditPage /> },
            { path: "blog/detail/:id", element: <BlogDetailPage /> },
          ],
        },
        {
          path: "contact-us/*",
          element: <ContactUs />,
          children: [
            { path: "contact-us/detail/:id", element: <ContactUsDetail /> },
          ],
        },
      ],
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "404",
      element: <Page404 />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
