import { alpha, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Card } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Logo from "../../components/logo";
import { AuthContext } from "../../context/AuthContext";
import InputAdornment from "@mui/material/InputAdornment";
import Iconify from "../../components/iconify";
import { bgGradient } from "../../theme/css";
import * as Yup from "yup";
import { baseService } from "../../service/baseService";
import { Helmet } from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";
import { pageTitle } from "../../utils/static-datas";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    position: "relative",
  },
  imageContainer: {
    display: "none",
    "@media (min-width: 769px)": {
      display: "flex",
      width: "50%",
    },
  },
};

const LoginPage = () => {

  const theme = useTheme();
  const navigate = useNavigate();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const { isLogin, setisLogin } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email alanı zorunludur")
        .email("Doğru bir email adresi giriniz"),
      password: Yup.string().required("Şifre alanı zorunludur"),
    }),
    onSubmit: (values, { setSubmitting, setErrors }) => {
      baseService
        .post("/auth/admin/login", values)
        .then((res) => {
          localStorage.setItem("token", res.token);
          setisLogin(true);
        })
        .catch((err) => {
          setErrors({
            submit: "Email veya şifre yanlış! Lütfen tekrar deneyin.",
          });
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (isLogin) navigate("/");
  }, [isLogin]);

  const renderForm = (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            onChange={formik.handleChange}
            label="Email adresi"
            value={formik.values.email}
            name="email"
            type="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            name="password"
            label="Şifre"
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            value={formik.values.password}
            onChange={formik.handleChange}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 3 }}
      >
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}
        <ReCAPTCHA
          style={{ marginTop: 20 }}
          sitekey="6LcI_q8pAAAAAB6icbkC95AeATgFJH9RrMuty_dG"
          onChange={value => setRecaptchaValue(value)}
        />

        <LoadingButton
          style={{ marginTop: 20 }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="inherit"
          disabled={!recaptchaValue}
        >
          Giriş yap
        </LoadingButton>
        {formik.errors.submit && (
          <div style={{ color: "red", marginTop: "2px" }}>
            {formik.errors.submit}
          </div>
        )}{" "}
      </form>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Giriş Yap | {pageTitle}</title>
      </Helmet>
      <Box
        sx={{
          ...bgGradient({
            color: alpha(theme.palette.background.default, 0.4),
            imgUrl: "/assets/background/background1.jpg",
          }),
          ...styles.container,
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 10,
            top: 10,
          }}
        >
          <Typography fontWeight="bold" fontSize={22}>
            <Logo />
          </Typography>
        </div>
        <Box sx={styles.imageContainer}>
          <img src="/assets/illustrations/illustration_login.png" />
        </Box>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography
            variant="h4"
            style={{
              marginBottom: 20,
            }}
          >
            Yönetim Paneline Giriş
          </Typography>
          {renderForm}
        </Card>
      </Box>
    </>
  );
};
export default LoginPage;
