import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./login";

const PublicLayout = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    </>
  );
};

export default PublicLayout;
