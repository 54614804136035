import { axiosInstance } from "./axiosInstance";

export const baseService = {
    get: async (url) => {
        try {
            const response = await axiosInstance.get(url);
            return response.data;
        } catch (error) {
        }
    },
    post: async (url, data, headers = {}) => {
        const response = await axiosInstance.post(url, data, { headers});
        return response.data;
    },
    put: async (url, data) => {
        const response = await axiosInstance.put(url, data);
        return response.data;
    },
    delete: async (url, id) => {
        const response = await axiosInstance.delete(url + "/" + id);
        return response.data;
    }
}