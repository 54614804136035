import PropTypes from "prop-types";
import { forwardRef } from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";

import { RouterLink } from "../../routes/components";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: "100%",
        height: 40,
        display: "inline-flex",
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 803.67 187.54" width={200}>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className="cls-1"
              style={{
                fill: "#1d1d1b",
              }}
              d="M300.91,75.45q9.33,9.09,9.31,27V149H286.61V106q0-9.69-4.23-14.46t-12.26-4.77q-8.94,0-14.23,5.52t-5.29,16.43V149H227V67.62h22.55v9.54a31.34,31.34,0,0,1,11.65-8,40.39,40.39,0,0,1,15.28-2.81Q291.62,66.35,300.91,75.45Z"
            />
            <path
              className="cls-1"
              style={{
                fill: "#1d1d1b",
              }}
              d="M354.63,143.82a52.82,52.82,0,0,1-20.58-19.54,57,57,0,0,1,0-56.48,52.65,52.65,0,0,1,20.58-19.53A65.67,65.67,0,0,1,409,46.07,49.75,49.75,0,0,1,427.65,60L411.91,74.54q-10.74-12.41-26.63-12.41a35.21,35.21,0,0,0-17.56,4.31,30.77,30.77,0,0,0-12,12,38,38,0,0,0,0,35.13,30.9,30.9,0,0,0,12,12,35.55,35.55,0,0,0,17.56,4.31q15.89,0,26.63-12.57l15.74,14.54a49,49,0,0,1-18.77,14.08,62.34,62.34,0,0,1-25,4.85A61,61,0,0,1,354.63,143.82Z"
            />
            <path
              className="cls-1"
              style={{
                fill: "#1d1d1b",
              }}
              d="M455.26,144.88a40.11,40.11,0,0,1-16-14.92,43.67,43.67,0,0,1,0-43.3,39.84,39.84,0,0,1,16-14.92,52,52,0,0,1,46.08,0,39.86,39.86,0,0,1,15.89,14.88,43.62,43.62,0,0,1,0,43.3,40.15,40.15,0,0,1-15.87,15,52.11,52.11,0,0,1-46.08,0Zm38-20.14q5.82-6.15,5.82-16.43t-5.82-16.43a19.62,19.62,0,0,0-14.91-6.13,19.9,19.9,0,0,0-15,6.13q-5.91,6.12-5.9,16.43t5.9,16.43a20,20,0,0,0,15,6.13,19.71,19.71,0,0,0,14.9-6.13Z"
            />
            <path
              className="cls-1"
              style={{
                fill: "#1d1d1b",
              }}
              d="M599.25,75.37q10.14,9,10.14,27.18V149H587.3V138.9q-6.66,11.35-24.82,11.35a38.71,38.71,0,0,1-16.27-3.18,24.38,24.38,0,0,1-10.52-8.78,23,23,0,0,1-3.63-12.67q0-11.37,8.55-17.87T567,101.24h18.76q0-7.74-4.69-11.88T567,85.19a40.61,40.61,0,0,0-12.79,2,33.58,33.58,0,0,0-10.67,5.52l-8.47-16.5a51.15,51.15,0,0,1,16-7.27,70.84,70.84,0,0,1,19.15-2.58Q589.13,66.35,599.25,75.37Zm-20.13,56.18a15.35,15.35,0,0,0,6.66-8.25V115H569.59q-14.52,0-14.53,9.53a8.49,8.49,0,0,0,3.56,7.19q3.57,2.66,9.76,2.66A20.54,20.54,0,0,0,579.12,131.55Z"
            />
            <path
              className="cls-1"
              style={{
                fill: "#1d1d1b",
              }}
              d="M647.6,144.88a41.08,41.08,0,0,1,0-73.14A50,50,0,0,1,671,66.36q12.87,0,22.47,5.38a31.86,31.86,0,0,1,14,15.37L689.14,97q-6.36-11.22-18.32-11.2a20.71,20.71,0,0,0-15.28,6q-6,6.06-6,16.51t6,16.5a20.74,20.74,0,0,0,15.28,6.06q12.12,0,18.32-11.2l18.31,10a32.53,32.53,0,0,1-14,15.14q-9.6,5.46-22.47,5.45A50.17,50.17,0,0,1,647.6,144.88Z"
            />
            <path
              className="cls-1"
              style={{
                fill: "#1d1d1b",
              }}
              d="M794.36,75.45q9.3,9.09,9.31,27V149H780.06V106q0-9.69-4.24-14.46t-12.25-4.77q-8.94,0-14.23,5.52T744,108.76V149h-23.6V36.69H744V76.06a31.54,31.54,0,0,1,11.35-7.2,41,41,0,0,1,14.53-2.5Q785.09,66.36,794.36,75.45Z"
            />
            <path
              className="cls-2"
              style={{
                fill: "#004b81",
              }}
              d="M145.88,60c0,13.47-.08,26.93.06,40.4,0,3.42-1,4.33-4.44,4a108.9,108.9,0,0,0-12.9.14c-2.86.09-4-1-4-4.06.36-15.1,0-30.21.29-45.32.17-8.23-.13-16.5-.22-24.75a3.09,3.09,0,0,0-2-3.16C116.61,24,110,22.55,103.42,21.66a70,70,0,0,0-44.75,8.49c-21.22,12-34,30.17-36.88,54.46-2.25,19.19-.42,38.56-.84,57.85,0,1.91.06,3.82,0,5.72,0,.92.43,2.29-.89,2.58-.91.21-1.49-.87-2-1.57a99,99,0,0,1-8.28-13.57A93.79,93.79,0,0,1,22,33.62,90.37,90.37,0,0,1,61.29,5.75a92.14,92.14,0,0,1,53.84-3.13A99.23,99.23,0,0,1,142.92,14a5.55,5.55,0,0,1,3,5.53C145.83,33,145.88,46.48,145.88,60Z"
            />
            <path
              className="cls-2"
              style={{
                fill: "#004b81",
              }}
              d="M167.22,36.72c4,3.24,6.34,7.78,8.7,12.16a96.77,96.77,0,0,1,8.15,20,93.46,93.46,0,0,1,2.41,37.69,88.75,88.75,0,0,1-20.55,47c-15.38,18.12-34.78,29.16-58.51,32.91a93.83,93.83,0,0,1-34.31-1.15c-10.36-2.17-20-6.48-29.07-11.84a5,5,0,0,1-2.55-4.7q.12-40.95,0-81.9c0-2.82.87-3.83,3.71-3.72a118.37,118.37,0,0,0,13.32-.06c3.21-.23,4.11,1.37,4,4-.67,22.21-.1,44.42-.28,66.63-.06,7.5-.91,5.77,6.14,8.38a70.06,70.06,0,0,0,43,2.41,72.36,72.36,0,0,0,41.69-28.91c10-14,13.62-29.31,13.42-46.08-.19-15.48-.06-31,0-46.48a55.12,55.12,0,0,1,.76-6.39Z"
            />
            <path
              className="cls-3"
              style={{
                fill: "#0cb6ea",
              }}
              d="M104.46,94c0,15.88-.07,31.76,0,47.63,0,3.47-1.15,4.83-4.65,4.66-4-.2-8-.44-12.07-1-4.55-.62-4.92-3.43-4.84-6.57.85-30.85.18-61.71.28-92.57,0-3.64.81-4.89,4.77-4.35a69.7,69.7,0,0,0,12.92,0c2.92-.15,3.68,1,3.66,3.73-.1,16.13,0,32.26,0,48.4Z"
            />
            <path
              className="cls-4"
              style={{
                fill: "#caab60",
              }}
              d="M167.26,36.68l-.35-.29h.21a.72.72,0,0,1,.1.29Z"
            />
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
