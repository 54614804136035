import { createContext, useState } from "react";
import { axiosInstance } from "../service/axiosInstance";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLogin, setisLogin] = useState(false);
  const [loading, setloading] = useState(true);

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        setisLogin(false);
      }
      if (error.response.status === 200 || error.response.status === 201) {
        setisLogin(true);
      }
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      } else {
        delete config.headers["Authorization"];
        setisLogin(false);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  return (
    <AuthContext.Provider value={{ isLogin, setisLogin, loading, setloading }}>
      {children}
    </AuthContext.Provider>
  );
};
