import React from "react";
import { useEffect } from "react";
import Views from "./views/index";
import { BrowserRouter } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import "../src/global.css";
import ThemeProvider from "../src/theme";
import { AuthProvider } from "./context/AuthContext";
import { LoginUserProvider } from "./context/LoginUserContext";

function App() {
  useEffect(() => {
    dayjs.locale("tr");
  }, []);

  return (
    <div className="app">
      <AuthProvider>
        <LoginUserProvider>
          <ThemeProvider>
            <BrowserRouter>
              <Views />
            </BrowserRouter>
          </ThemeProvider>
        </LoginUserProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
