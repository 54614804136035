import React, { useContext, useEffect } from "react";
import PrivateLayout from "./private";
import PublicLayout from "./public";
import { AuthContext } from "../context/AuthContext";
import { baseService } from "../service/baseService";
import { Box, CircularProgress } from "@mui/material";

const Views = () => {
  const { isLogin, setisLogin, loading, setloading } = useContext(AuthContext);

  useEffect(() => {
    setloading(true);
    baseService
      .get("/auth/admin/login")
      .then((res) => {
        if (res) {
          setloading(false);
          setisLogin(true);
        } else {
          setloading(false);
          setisLogin(false);
        }
      })
      .catch(() => setisLogin(false));
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={50}/>
      </Box>
    );
  }
  return isLogin ? <PrivateLayout /> : <PublicLayout />;
};

export default Views;
